import React from "react";
import Navbar from "./components/Navbar";
import Skills from "./components/Skills";
import Experience from "./components/Experience";
import Projects from "./components/Projects";
import Certificates from "./components/Certificates";
import Reviews from "./components/Reviews";
import Contact from "./components/Contact";
import AboutMe from "./components/AboutMe";

function App() {
  return (
    <div className="App">
      <Navbar />
      <AboutMe />
      <Skills />
      <Experience />
      <Projects />
      <Certificates />
      <Reviews />
      <Contact />

      <footer className="text-center text-gray-400 py-4">
        <p>&copy; 2021 Bimasha Zaman. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
