import React from "react";

const experienceData = [
  {
    company: "VARYGEN CORP LIMITED, United Kingdom",
    role: "Founder & CEO",
    duration: "08/2023 - Present",
    descriptions: [
      "Spearheaded the development of a robust AI-driven platform, integrating OpenAI API’s to enhance predictive analytics and decision-making processes.",
      "Implemented Agile frameworks and DevOps practices, significantly improving deployment cycles and system resilience.",
    ],
  },
  {
    company: "Life Coach Hub, United States",
    role: "Full Stack Software Engineer",
    duration: "12/2023 - Present",
    descriptions: [
      "Engineered scalable web applications leveraging React and Laravel, ensuring high performance under heavy load conditions.",
      "Enhanced platform security by integrating advanced cybersecurity measures and conducting regular security audits.",
    ],
  },
  {
    company: "MY SAAS TEAM, Australia",
    role: "Software Engineer",
    duration: "09/2023 - 04/2024",
    descriptions: [
      "Drove front-end development using contemporary frameworks like React and Next.js, focusing on responsive design and accessibility.",
      "Optimized backend operations with Python and Django, enhancing API efficiency and reducing server response times.",
    ],
  },
  {
    company: "Gjiganti, Finland",
    role: "Senior Software Engineer",
    duration: "11/2022 - 11/2023",
    descriptions: [
      "Developed and deployed scalable cloud-based applications, facilitating seamless scalability and improved load balancing.",
      "Led database optimization initiatives using PostgreSQL, improving data processing and storage efficiency.",
    ],
  },
  {
    company: "Evolov, Norway",
    role: "Lead Software Engineer",
    duration: "09/2022 - 08/2023",
    descriptions: [
      "Directed the development of a Next.js/Django platform, increasing user engagement and platform revenue by 30% and 20% respectively.",
      "Implemented Agile practices, reducing product development cycles by 25%.",
    ],
  },
  {
    company: "Poker Social LTD, India",
    role: "Senior Software Engineer",
    duration: "11/2022 - 07/2023",
    descriptions: [
      "Launched React Native mobile and Laravel web applications, demonstrating multifaceted expertise.",
      "Implemented advanced security measures, ensuring data integrity and application robustness.",
    ],
  },
  {
    company: "Admission Pedia, India",
    role: "Senior Software Engineer",
    duration: "10/2022 - 02/2023",
    descriptions: [
      "Led Next.js application development, enhancing features and boosting user satisfaction by 60%.",
      "Mentored juniors, accelerating team productivity by 15%.",
    ],
  },
  {
    company: "2expedition, Armenia",
    role: "Full Stack Software Engineer",
    duration: "05/2022 - 08/2022",
    descriptions: [
      "Directed full-cycle web app development, crafting user-centric solutions that significantly boosted engagement.",
      "Embraced agile methodologies like Scrum, fine-tuning workflows for on-time project delivery.",
    ],
  },
  {
    company: "Npmcode LLC, Pakistan",
    role: "Software Engineer",
    duration: "01/2022 - 07/2022",
    descriptions: [
      "Assembled and led a standout startup team, crafting a potent marketing and business strategy.",
      "Directed a development team to deliver top-tier service quality.",
    ],
  },
  {
    company: "Tech Expert, Bangladesh",
    role: "Mentor",
    duration: "2015 - 2019",
    descriptions: [
      "Empowered students with full-stack development knowledge, transforming novices into seasoned professionals.",
      "Created engaging lessons on advanced tech stacks, making intricate code comprehensible.",
      "Cultivated problem-solving skills, equipping students for real-world challenges.",
    ],
  },
];

const Experience = () => {
  return (
    <section
      className="text-white py-24 bg-black relative overflow-hidden"
      id="experience"
    >
      <div className="container mx-auto px-8">
        <h2 className="text-5xl font-extrabold text-center mb-20 text-gray-100">
          Professional Experience
        </h2>
        <div className="space-y-10">
          {experienceData.map((job, index) => (
            <div
              key={index}
              className="group transform transition duration-500 ease-in-out hover:-translate-y-2 hover:scale-105"
              style={{
                boxShadow:
                  "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div className="p-8 rounded-xl bg-[#1f1f1f] bg-opacity-80 shadow-2xl">
                <h3 className="text-3xl font-bold text-gray-100">
                  {job.company}
                </h3>
                <p className="text-xl font-semibold text-blue-400">
                  {job.role}
                </p>
                <p className="text-md text-gray-500">{job.duration}</p>
                <ul className="list-disc list-inside space-y-2 text-left pl-5 text-gray-300 mt-4">
                  {job.descriptions.map((desc, descIndex) => (
                    <li key={descIndex} className="text-lg">
                      {desc}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Experience;
