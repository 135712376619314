import React from "react";

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="relative w-full h-full bg-white items-center justify-center">
        <button
          onClick={onClose}
          className="absolute top-0 right-0 m-4 text-gray-900 font-semibold leading-none rounded hover:bg-gray-100 focus:outline-none z-50"
        >
          &times;
        </button>
        {children}
      </div>
      {/* <div
        className="overlay absolute inset-0 bg-black opacity-75"
        onClick={onClose}
      ></div> */}
    </div>
  );
};

export default Modal;
