import React, { useState, useEffect } from "react";
import Modal from "./Modal";

const Certificates = () => {
  const [images, setImages] = useState([]);
  const [visibleCount, setVisibleCount] = useState(3);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    // Dynamically load images
    const loadImageContext = () => {
      try {
        const context = require.context(
          "../../public/CertificateImages", // Adjust this path as necessary
          false,
          /\.(jpe?g|png)$/
        );
        const loadedImages = context.keys().map(context);
        setImages(loadedImages);
      } catch (error) {
        console.error("Error loading images:", error);
      }
    };

    loadImageContext();
  }, []);

  const showMoreCertificates = () => {
    setVisibleCount((prevCount) => prevCount + 10);
  };

  const openModal = (img) => {
    setSelectedImage(img);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <section id="certificates" className="bg-gray-900 py-20">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center text-white mb-12">
          Certificates
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {images.slice(0, visibleCount).map((img, index) => (
            <div
              key={index}
              className="bg-white p-2 rounded-lg shadow-lg cursor-pointer"
            >
              <img
                src={img}
                alt={`Certificate ${index + 1}`}
                className="w-full h-auto rounded-lg"
                // onClick={() => openModal(img)}
              />
            </div>
          ))}
        </div>
        {visibleCount < images.length && (
          <div className="text-center mt-8">
            <button
              onClick={showMoreCertificates}
              className="border border-white text-white px-8 py-4 mt-4 rounded-lg hover:bg-white hover:text-gray-900 transition duration-300"
            >
              Show More Certificates
            </button>
          </div>
        )}
        <Modal isOpen={modalOpen} onClose={closeModal}>
          <img
            src={selectedImage}
            alt="Selected Certificate"
            className="max-w-full h-auto"
          />
        </Modal>
      </div>
    </section>
  );
};

export default Certificates;
