import React from "react";

const reviewsData = [
  {
    id: 1,
    name: "Simon Ogilvie-Lee",
    review:
      "Bimasha was truly awesome. She was quick to respond, even quicker to produce results. She communicates effectively, is always willing to further refine her code. Her code is clean, abstracted, and matches designs better than most developers I've seen. A great asset to any team. Hope to have more work for her in future.",
    rating: 5,
  },
  {
    id: 4,
    name: "N Yeran Ian",
    review:
      "This was my second experience working with Bimasha. When we were looking for a developer to make our website, we thought that we need to find a separate front-end specialist first, and then a separate back-end specialist. Bimasha initially completed the frontend in a short time and with high quality, and it turned out that she will also do the backend.We trusted her for the backend and I am very satisfied with the result. First of all, I want to mention her professional quality, she knows her work well and does it very quickly. Easy to communicate with, available most of the day and pleasant to talk to. I want to emphasize that she is a research programmer, she studies all options and offers a new solution.In short, I am very satisfied with her work and if needed, I will contact her again and recommend her to others.",
    rating: 5,
  },
  {
    id: 7,
    name: "Aqdas",
    review:
      "Wow! Incredibly helpful, I must say, she is a Porsche with no breaks! who went out of her way to help us to provide a quick fix for the issues we were facing in our application code. I would highly recommend getting Bimasha Zaman on your team early if you are looking for some one who is really an expert",
    rating: 5,
  },
  {
    id: 9,
    name: "Maruf Sayed",
    review:
      "Bimasha Zaman on the cutting edge of software engineering and consistently thinks outside the box. She is an excellent recourse and a master at programming even she is very young compare with other programmer. Bimasha Zaman has been a tremendous asset to the clients and contacts and has frequently facilitated introductions and others direct contacts.",
    rating: 5,
  },
  {
    id: 10,
    name: "Dipto Dutta",
    review:
      "Bimasha Zaman is perspicacious and diligent. I got a great opportunity to work with her. During working with her, the thing I liked the most was her seriousness and painstakingness at work. I must appreciate her skills. I will give her 5-star rating out of 5.",
    rating: 5,
  },
  {
    id: 2,
    name: "Sunaram",
    review:
      "It was great working with Bimasha. She has very good front-end dev skills and is highly ethical.",
    rating: 5,
  },
  {
    id: 3,
    name: "Ferdal Karaman",
    review:
      "She's so helpful and very talented, she completed the work as fast as possible. I really appreciate her work, and definitely going to work with her again.",
    rating: 5,
  },

  {
    id: 5,
    name: "N Yeran Ian",
    review:
      "They finalized the front end quite well. Anyway, thanks to their team, the quality of the job is quite well.",
    rating: 5,
  },
  {
    id: 6,
    name: "Florian Uhlmann",
    review:
      "Working together was amazing. I really liked her fast pace in work and reframing my requirements to better ones. Will hire again in furture :)",
    rating: 5,
  },

  {
    id: 8,
    name: "Saif Mohamed",
    review:
      "Bimasha is one of the best software engineers I've ever seen, she is motivated and talented and the most important one is that she is so cooperative and kind.",
    rating: 5,
  },

  {
    id: 11,
    name: "Rose Malley",
    review:
      "Bimasha Zaman is amazing php developer. She is so responsible and expert. She delivered the complex project before the deadline.she is a great team player and she has a amazing communication skills",
    rating: 5,
  },
  {
    id: 12,
    name: "Kamruzzaman shahin",
    review:
      "Bimasha is so energetic developer. Her working pattern is different then others. I will give her 5 out of 5 stars.",
    rating: 5,
  },
];

// Function to render star ratings
const renderStars = (rating) => {
  return (
    <div className="flex mb-4">
      {[...Array(5)].map((_, index) => (
        <span
          key={index}
          className={`fa fa-star ${
            index < rating ? "text-yellow-400" : "text-gray-600"
          } animate-pulse`}
        >
          &#9733;
        </span>
      ))}
    </div>
  );
};

const Reviews = () => {
  return (
    <section
      className="bg-gradient-to-br from-gray-900 to-gray-800 text-white py-20"
      id="reviews"
    >
      <div className="container mx-auto px-4">
        <h2 className="text-5xl font-bold text-center text-gray-300 mb-12">
          Client Reviews & Testimonials
        </h2>
        <p className="text-center text-lg text-gray-400 mb-12">
          Source: Upwork and LinkedIn
        </p>
        <br />
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 mt-1">
          {reviewsData.map((review) => (
            <div
              key={review.id}
              className="bg-gray-800 p-8 rounded-xl shadow-xl transition duration-300 ease-in-out transform hover:scale-110 hover:bg-gray-700"
            >
              <h3 className="text-2xl font-semibold mb-2">{review.name}</h3>
              {renderStars(review.rating)}

              <p className="text-lg leading-relaxed">{review.review}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Reviews;
