import React from "react";

// Skill categories with detailed proficiencies
const skillsData = [
  {
    category: "Languages",
    skills: ["JavaScript", "TypeScript", "Python", "PHP"],
  },
  {
    category: "Frontend Development",
    skills: [
      "React",
      "TailwindCSS",
      "Bootstrap",
      "Alpine.js",
      "Vue js",
      "HTML",
      "CSS",
      "Next.js",
      "Redux",
    ],
  },
  {
    category: "Backend Development",
    skills: ["Node.js", "PHP", "Laravel", "Express JS"],
  },
  {
    category: "Databases",
    skills: ["MongoDB", "MySQL", "PostgreSQL", "Firebase"],
  },
  {
    category: "DevOps Tools",
    skills: ["Docker", "Kubernetes", "GIT", "Postman", "JIRA"],
  },
  {
    category: "Cloud Platforms",
    skills: ["AWS", "Google Cloud"],
  },
  {
    category: "Mobile Development",
    skills: ["React Native", "Swift"],
  },
  {
    category: "Others",
    skills: ["Agile", "Scrum", "Kanban", "Jest", "Mocha", "Chai"],
  },
];

const Skills = () => {
  return (
    <section className=" text-white py-24" id="skills">
      <div className="container mx-auto px-6">
        <h2 className="text-5xl font-extrabold text-center mb-16 text-white">
          Professional Skills
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-10">
          {skillsData.map((skillCategory, index) => (
            <div
              key={index}
              className="relative overflow-hidden bg-black bg-opacity-80 p-8 rounded-xl shadow-2xl hover:shadow-md transition-all duration-500 ease-in-out transform hover:scale-105"
            >
              <h3 className="text-3xl font-semibold mb-6">
                {skillCategory.category}
              </h3>
              <ul className="list-disc list-inside space-y-3 text-lg">
                {skillCategory.skills.map((skill, skillIndex) => (
                  <li
                    key={skillIndex}
                    className="hover:text-blue-300 transition-colors duration-300"
                  >
                    {skill}
                  </li>
                ))}
              </ul>
              <div className="absolute inset-0 w-full h-full bg-gradient-to-br from-transparent to-gray-900 transition-opacity duration-500 ease-in-out opacity-0 hover:opacity-100"></div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Skills;
