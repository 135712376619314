import React, { useState } from "react";
import { Link } from "react-scroll";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false); // State to manage the menu toggle

  // Navigation links data
  const navLinks = [
    { id: "about-me", text: "About Me" },
    { id: "skills", text: "Skills" },
    { id: "experience", text: "Experience" },
    { id: "projects", text: "Projects" },
    { id: "certificates", text: "Certificates" },
    { id: "reviews", text: "Reviews" },
    { id: "contact", text: "Contact" },
  ];

  return (
    <nav
      className="p-4 items-center mx-auto fixed w-full z-10 shadow-md text-white font-semibold  transition duration-300 ease-in-out justify-center bg-gray-900/90"
      style={{ top: 0, zIndex: 1000 }}
    >
      <div className=" w-full container mx-auto flex justify-center items-center texts-center">
        {/* <div className="text-2xl font-bold">Bimasha</div> */}
        <div className="align-middle w-full text-center mx-auto justify-center  showOnLargeDeviceAndHideOnSmall">
          <ul className="flex space-x-24 items-center justify-center">
            {navLinks.map((link) => (
              <li
                key={link.id}
                className=" p-2 rounded-lg cursor-pointer transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110 p-2 hover:text-gray-300 hover:border-gray-900"
              >
                <Link
                  to={link.id}
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  className="cursor-pointer"
                >
                  {link.text}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="block md:hidden text-right w-full text-3xl">
          <button onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? <span>&times;</span> : <span>&#9776;</span>}
          </button>
        </div>
      </div>
      <div className={`${isOpen ? "block" : "hidden"} md:hidden`}>
        <ul className="h-screen w-full  items-center text-2xl font-semibold transition duration-300 ease-in-out transform ">
          {navLinks.map((link) => (
            <li
              key={link.id}
              className=" p-2 text-center hover:bg-gray-800 hover:text-white transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110 p-2 hover:text-gray-300 hover:border-gray-900"
            >
              <Link
                to={link.id}
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className="block p-2"
                onClick={() => setIsOpen(false)}
              >
                {link.text}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
