import React from "react";
import Slider from "react-slick";

const Projects = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const projectsData = [
    {
      name: "VaryGen: Best AI Tools",
      role: "Founder & Lead Developer",
      description:
        "Developed an AI-driven platform to enhance analytics and decision-making.",
      link: "https://varygen.com",
    },
    {
      name: "AdmissionPedia: Educational Platform",
      role: "Lead Developer",
      description:
        "Led the development of an educational platform aimed at streamlining the college admission process.",
      link: "https://admissionpedia.com",
    },
    {
      name: "Evolov: Job Matching Application",
      role: "Project Lead",
      description:
        "Directed a team in building a job matching platform that leverages advanced algorithms for job seekers and employers.",
      link: "https://evolov.com",
    },
    {
      name: "PixGround: Wallpaper Platform",
      role: "Full Stack Developer",
      description:
        "Engineered a high-traffic wallpaper platform catering to high-definition multimedia content.",
      link: "https://pixground.com",
    },
    {
      name: "Fineas: Financial Management",
      role: "Lead Frontend Developer",
      description:
        "Crafted a comprehensive financial management tool designed to simplify personal and small business finances.",
      link: "https://fineas.io",
    },
    {
      name: "Life Coach Hub: Online Coaching Platform",
      role: "Full Stack Engineer",
      description:
        "Developed a comprehensive platform for life coaches to manage clients and sessions efficiently.",
      link: "https://coaching-software.lifecoachhub.com/",
    },
  ];

  return (
    <>
      <style>
        {`
          .slick-slide {
            margin: 0 15px;
          }
          .slick-slide:focus {
            outline: none;
          }
          @media (max-width: 600px) {
            .slick-slide {
              margin: 0 10px;
            }
          }
        `}
      </style>
      <section className="text-white py-20 bg-black text-center" id="projects">
        <h2 className="text-4xl font-bold text-center mb-12">
          Recent Projects
        </h2>
        <div className="container mx-auto px-4">
          <br />
          <Slider {...settings}>
            {projectsData.map((project, index) => (
              <div
                key={index}
                className="p-8 bg-[#1f1f1f] border border-[#333333]
                 rounded-xl shadow-2xl"
              >
                {/* <img
                  src={project.image}
                  alt={project.name}
                  className="rounded-t-xl mb-4"
                /> */}
                <h3 className="text-3xl font-bold">{project.name}</h3>
                {/* <p className="text-blue-400 mb-2">{project.role}</p> */}
                <p className="text-gray-400 m-4">{project.description}</p>
                <a
                  href={project.link}
                  className="text-blue-500 hover:text-blue-300 transition duration-300"
                >
                  View Project
                </a>
              </div>
            ))}
          </Slider>
        </div>
      </section>
    </>
  );
};

export default Projects;
