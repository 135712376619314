import React, { useState } from "react";
import emailjs from "emailjs-com";
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaGithub } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import css

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_9z8mcjc",
        "template_4l86jrm",
        formData,
        "IJ05rJVpQfd3Dxv-0"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setFormData({ name: "", email: "", message: "" });
          toast.success("Message sent successfully");
        },
        (err) => {
          console.log("FAILED...", err);
          toast.error("Failed to send message");
        }
      );
  };

  return (
    <section className="bg-black text-white py-20" id="contact">
      <div className="container mx-auto px-4 flex justify-center items-center flex-wrap">
        <div className=" mb-6 md:mb-0 flex flex-col items-center lg:w-1/2 md:w-1/2">
          <img
            src="/me.png"
            alt="Your Name"
            className="rounded-3xl shadow-2xl mb-4 object-cover"
          />
          <p className="text-center text-lg mb-4">
            Let's create something great together.
          </p>

          <div className="flex justify-center space-x-6">
            <a
              href="https://facebook.com/bimashazaman"
              className="text-gray-400 hover:text-blue-600"
            >
              <FaFacebookF size={30} />
            </a>

            <a
              href="https://linkedin.com/in/bimasha-zaman"
              className="text-gray-400 hover:text-blue-700"
            >
              <FaLinkedinIn size={30} />
            </a>
            <a
              href="https://github.com/bimashazaman"
              className="text-gray-400 hover:text-gray-600"
            >
              <FaGithub size={30} />
            </a>
          </div>
        </div>
        <div className="lg:w-1/2 md:w-1/2">
          <form onSubmit={handleSubmit} className=" p-8 h-full">
            <h2 className="text-3xl font-bold mb-6">Contact Me</h2>
            <p className="text-gray-400 mb-6">
              Have a question or want to work together? Send me a message! 🚀
            </p>
            <div className="mb-4">
              <label className="block text-gray-400 text-sm font-bold mb-2">
                Name
              </label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline bg-transparent hover:bg-gray-800"
                required
                placeholder="Your Name"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-400 text-sm font-bold mb-2">
                Email
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 bg-transparent hover:bg-gray-800 text-white leading-tight focus:outline-none focus:shadow-outline"
                required
                placeholder="Your Email"
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-400 text-sm font-bold mb-2">
                Message
              </label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 bg-transparent hover:bg-gray-800 text-white leading-tight focus:outline-none focus:shadow-outline h-32"
                required
                placeholder="Your Message"
              ></textarea>
            </div>
            <br />

            <button
              type="submit"
              className="w-full border border-white text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline hover:bg-gray-800"
            >
              Send Message
            </button>
          </form>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </section>
  );
};

export default Contact;
