import React from "react";
import { Link } from "react-scroll";

const AboutMe = () => {
  return (
    <section
      className="bg-black text-white py-24 text-center relative overflow-hidden pt-[100px] md:pt-[150px] lg:pt-[200px] xl:pt-[250px] pb-[100px] md:pb-[150px] lg:pb-[200px] xl:pb-[250px] overflow-hidden"
      id="about-me"
    >
      <div className="container mx-auto px-6">
        <h1 className="text-7xl font-extrabold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-gray-300 to-gray-500">
          Bimasha Zaman
        </h1>
        <h2 className="text-3xl md:text-4xl font-semibold mb-5 text-gray-300">
          Full-Stack Developer | Software Engineer | Tech Enthusiast
        </h2>
        <br />
        <p className="text-xl md:text-2xl font-medium mb-6 leading-relaxed mx-auto max-w-4xl">
          With a robust background in full-stack development and over eight
          years of real-world experience, I specialize in transforming complex
          software challenges into sleek, scalable solutions. My focus is on
          enhancing user experiences and driving business growth through
          technology.
        </p>
        <br />
        <p className="text-lg md:text-xl font-light mb-6 text-gray-500 mx-auto max-w-3xl">
          "Dedicated to delivering technology that innovates and excels."
        </p>
        <br />
        <Link
          to="contact"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          className="inline-block bg-gradient-to-br from-gray-700 to-gray-900 hover:bg-gray-800 text-white font-bold py-3 px-10 rounded-lg transition-all duration-300 ease-in-out shadow-lg transform hover:-translate-y-1"
        >
          Explore Opportunities Together
        </Link>
      </div>
    </section>
  );
};

export default AboutMe;
